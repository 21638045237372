<template>
  <div class="about">
    <h1>About ManyPong</h1>
    <p>ManyPong is the place to keep track of your important wins at the Manyone Ping-pong table(s).</p>
  </div>
  <sign-in message="To participate you need to sign in using your manyone email."/>
  <h3>BETA testing</h3>
  <p>We are currently in BETA testing and focusing on making sure that the data layer works as expected.</p>
  <p>If you have comments, ideas or want to report a bug please contact <strong>Patrik Svensson</strong></p>
</template>

<script>
  import SignIn from '@/components/SignIn'
  export default {
    inject: ['playerStore'],
    components: {
      'sign-in': SignIn
    }
  }
</script>

<style scoped lang="scss">
.about {
  //
}
</style>